
import moment from 'moment'

import dictionaryServices from '../../services/dictionary.service'
import distributorServices from '../../services/Distributor/distributor.service'

const rowValidator = row => {

  console.log('rowValidator:', row)
  if (!row['Price'] || row['Price'] <= 0) {
    return { result: false, message: 'Price must be greater or equal than zero' };
  }


  if (!row['Valid From']?.length) {
    //   row['Valid From'] = moment().format('YYYY-MM-DD')
    return { result: false, message: 'Valid From must be not empty' };
  }


  if (!row['Currency']?.length) {
    row['Currency'] = 'USA, Dollars'
    row['Currency_JSON'] = { id: 120, label: 'USA, Dollars' }
  }

  return { result: true };
};

export default {
  distributorProductPrices: {
    name: 'distributor_product_prices',
    entities: [
      {
        name: 'ID',
        type: 'string',
        readonly: true,
        visible: false
      },

      {
        name: 'Distributor ID',
        type: 'number',
        readonly: true,
        required: true,

      },
      {
        name: 'Category',
        type: 'string',
        readonly: true
      },

      {
        name: 'Product',
        type: 'dropdown',
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchProducts,

      },

      {
        name: 'Market',
        type: 'dropdown',
        readonly: false,
        optionsService: dictionaryServices.fetchMarkets
      },

      {
        name: 'Price',
        type: 'number',
        readonly: false
      },
      {
        name: 'Currency',
        type: 'dropdown',
        readonly: false,
        optionsService: dictionaryServices.fetchCurrencies,
        defaultValue: { id: 120, label: 'USA, Dollars' }
      },
      {
        name: 'Price Type',
        type: 'string',
        readonly: true
      },
      {
        name: 'Valid From',
        alias: 'Pricelist Date',
        type: 'date',
        readonly: false,
        singleDatePicker: true,
        // defaultValue: moment().format('YYYY-MM-DD')
      },
      {
        name: 'Valid To',
        type: 'date',
        singleDatePicker: true,
        readonly: false
      },
      {
        name: 'Price History',
        type: 'string',
        readonly: true
      },
      
    ],
    services: {
      fetchRecord: () => { },
      fetchData: distributorServices.fetchDistributorProductPrices,
      createRecord: () => { },
      saveRecord: distributorServices.saveDistributorProductPrices,
      deleteRecord: distributorServices.deleteDistributorProductPrices
    },
    actions: {

      Edit: {
        validator: () => true
      },

      Delete: {
        validator: () => true,
        //Virtual records have no ID but have long GUID
        visible: (row) => row['ID'] && row['ID'].length < 10
      }
    },
    rowValidator
  }
}
