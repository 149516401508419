var render = function render(){
  var _vm$data$project,
    _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.user_name.id,
      "value": _vm.data.user_name,
      "label": _vm.controls.user_name.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    ref: _vm.controls.category.id,
    attrs: {
      "id": _vm.controls.category.id,
      "value": _vm.data.category,
      "label": _vm.controls.category.label,
      "readonly": _vm.controls.category.readonly,
      "options": _vm.controls.category.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.account.id,
      "width": 25,
      "link": _vm.controls.account.link,
      "value": _vm.data.account,
      "label": _vm.controls.account.label,
      "allow-empty": false,
      "readonly": _vm.controls.account.readonly,
      "options": _vm.controls.account.options,
      "mode": _vm.mode,
      "link-mode": true,
      "loading": _vm.controls.account.loading,
      "async": true,
      "required": true
    },
    on: {
      "async-search": _vm.onAccountSearch,
      "link-clicked": _vm.onAccountLinkClicked,
      "changed": _vm.updateSelect
    }
  }), _c('button', {
    staticClass: "btn btn-secondary btn align-self-center ml-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addAccount();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-toolbar fa-plus",
    staticStyle: {
      "color": "#fff"
    }
  })])], 1)]), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.contact.id,
      "value": _vm.data.contact,
      "loading": _vm.controls.contact.isLoading,
      "label": _vm.controls.contact.label,
      "readonly": _vm.controls.contact.readonly,
      "options": _vm.controls.contact.options,
      "allow-empty": true,
      "multiple": true,
      "mode": _vm.mode,
      "link-mode": true,
      "required": false
    },
    on: {
      "link-clicked": _vm.onContactLinkClicked,
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.activitydate.id,
      "value-single": _vm.data.activitydate,
      "label": _vm.controls.activitydate.label,
      "readonly": _vm.controls.activitydate.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-time-picker', {
    attrs: {
      "id": _vm.controls.time_start.id,
      "value": _vm.data.time_start,
      "label": 'From time',
      "readonly": _vm.controls.time_start.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-time-picker', {
    attrs: {
      "id": _vm.controls.time_end.id,
      "value": _vm.data.time_end,
      "label": 'To time',
      "readonly": _vm.controls.time_end.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-end"
  }, [_c('inline-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": _vm.controls.project.id,
      "value": _vm.data.project,
      "label": _vm.controls.project.label,
      "readonly": _vm.controls.project.readonly,
      "options": _vm.controls.project.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": false,
      "link-mode": true
    },
    on: {
      "changed": _vm.updateSelect,
      "link-clicked": _vm.onProjectLinkClicked
    }
  }), (_vm$data$project = _vm.data.project) !== null && _vm$data$project !== void 0 && _vm$data$project.id ? _c('delete-button', {
    staticClass: "ml-2 mb-1",
    on: {
      "click": function click($event) {
        return _vm.unlinkProject(_vm.controls.project.id);
      }
    }
  }) : _vm._e()], 1)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.subject.id,
      "value": _vm.data.subject,
      "label": _vm.controls.subject.label,
      "readonly": _vm.controls.subject.readonly,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "chevron-down"
    }
  }), _c('h6', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-1",
      modifiers: {
        "collapse-1": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Description ")])], 1)], 1), _c('b-collapse', {
    attrs: {
      "id": "collapse-1"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('ckeditor', {
    ref: "ckeditor",
    attrs: {
      "id": _vm.controls.description.id,
      "editor": _vm.editor,
      "disabled": _vm.controls.editor.readonly,
      "config": _vm.controls.editor.config
    },
    on: {
      "blur": _vm.onEditorBlur
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "chevron-down"
    }
  }), _c('h6', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-2",
      modifiers: {
        "collapse-2": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Tastings ")])], 1)], 1), _c('b-collapse', {
    attrs: {
      "id": "collapse-2"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.visitors.id,
      "value": _vm.data.visitors,
      "label": _vm.controls.visitors.label,
      "readonly": _vm.controls.visitors.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.expenses.id,
      "value": _vm.data.expenses,
      "label": _vm.controls.expenses.label,
      "readonly": _vm.controls.expenses.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('h5', [_vm._v("Products sold")]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.WhiteWhisky.id,
      "value": _vm.data.WhiteWhisky,
      "label": _vm.controls.WhiteWhisky.label,
      "readonly": _vm.controls.WhiteWhisky.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.Whisky.id,
      "value": _vm.data.Whisky,
      "label": _vm.controls.Whisky.label,
      "readonly": _vm.controls.Whisky.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.Gin.id,
      "value": _vm.data.Gin,
      "label": _vm.controls.Gin.label,
      "readonly": _vm.controls.Gin.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.Vodka.id,
      "value": _vm.data.Vodka,
      "label": _vm.controls.Vodka.label,
      "readonly": _vm.controls.Vodka.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.Liqueurs.id,
      "value": _vm.data.Liqueurs,
      "label": _vm.controls.Liqueurs.label,
      "readonly": _vm.controls.Liqueurs.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.Rum.id,
      "value": _vm.data.Rum,
      "label": _vm.controls.Rum.label,
      "readonly": _vm.controls.Rum.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.GinGiftPack.id,
      "value": _vm.data.GinGiftPack,
      "label": _vm.controls.GinGiftPack.label,
      "readonly": _vm.controls.GinGiftPack.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.WhiskeyGiftPack.id,
      "value": _vm.data.WhiskeyGiftPack,
      "label": _vm.controls.WhiskeyGiftPack.label,
      "readonly": _vm.controls.WhiskeyGiftPack.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.Other.id,
      "value": _vm.data.Other,
      "label": _vm.controls.Other.label,
      "readonly": _vm.controls.Other.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1)], 1)], 1)]), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e()], 1), _c('hr'), _vm.action !== 'preview' ? _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      },
      "custom-buttons": [{
        text: 'Mark as Reviewed',
        icon: 'signature',
        loading: false,
        visible: _vm.isReviewer && !(_vm.mode === _vm.$constants.FORM_MODE.CREATE) && !_vm.data.reviewed && _vm.$permitted(_vm.controls.buttons.set_reviewed.id).visible,
        method: 'onReviewedClick',
        variant: 'warning'
      }, {
        text: 'Schedule task',
        icon: 'clock',
        loading: false,
        visible: !(_vm.mode === _vm.$constants.FORM_MODE.CREATE),
        method: 'onCreateTaskClick',
        variant: 'primary',
        menu: [{
          label: '1 day later',
          method: 'onCreateTaskClickDay'
        }, {
          label: '1 week later',
          method: 'onCreateTaskClickWeek'
        }, {
          label: '2 weeks later',
          method: 'onCreateTaskClickTwoWeeks'
        }, {
          label: '1 month later',
          method: 'onCreateTaskClickMonth'
        }]
      }]
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push(_vm.$store.getters['router/previousRoute']) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Activity submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Activity submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      },
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  }) : _vm._e()], 1), _c('project-preview-modal', {
    ref: "project-preview-modal"
  }), _c('b-modal', {
    ref: "contact-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.contactPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openContactInNewWindow(_vm.contactPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeContactPreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('ContactSubmissionForm', {
    attrs: {
      "id": _vm.contactPreviewModal.id,
      "action": "preview"
    }
  })], 1)], 1), _c('b-modal', {
    ref: "account-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.accountPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openAccountInNewWindow(_vm.accountPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeAccountPreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh",
      "border": "none !important"
    }
  }, [_c('AccountSubmissionForm', {
    attrs: {
      "id": _vm.accountPreviewModal.id,
      "action": "preview"
    }
  })], 1)]), _c('b-modal', {
    ref: "task-submission-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "hide-footer": "",
      "title": _vm.taskSubmissionModal.title
    }
  }, [_c('TaskSubmissionForm', {
    attrs: {
      "action": "create",
      "properties": _vm.taskSubmissionModal.data,
      "create-task-action": true
    },
    on: {
      "save": _vm.onTaskModalSave,
      "close": _vm.onTaskModalClose
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }