<template>
  <div>
    <filtering-panel
      ref="filteringPanel"
      mode="server"
      :dataset-name="model.name"
      :load-dictionaries="loadDictionaries"
      :show-labels="true"
      :hide-custom-filters="false"
      :filters="filteringPanel.filters"
      @search="getData"
      @change="filterData"
      :loaded="filteringPanel.loaded"
      @dict-loaded="onFilteringPanelLoad"
      @custom-filter-selected="onCustomFilterSelected"
      @state-changed="onFilteringPanelStateChange"
    />
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";


export default {
  name: "FilteringPanelWrapper",
  components: {
    FilteringPanel
  },
  props: {
    model: {
      type: Object,
      default: () => {},
      required: true
    },
    filters: {
      type: Array,
      default: () => {},
      required: true
    }
  },
  data: function() {
    return {
      filterEntities: [],
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: []
      }
    };
  },
  computed: {},

  created() {
    this.filters.forEach(f => {
      this.filterEntities.push(this.model.entities.find(e => e.name == f));
    });
console.log("FilteringPanelWrapper.created.filterEntities:", this.filterEntities);
    this.filterEntities.forEach(e => {
      let f = undefined;

      if (e.type === "dropdown")
        f = {
          type: "select",
          dataType: "string",
          tooltip: e.name,
          title: e.name,
          name: this.getFilterName(this.model.name, e.name),
          trackby: "id",
          label: "label",
          options: [],
          selected: {},

          multiple: Object.prototype.hasOwnProperty.call(e, "multiple")
            ? e.multiple
            : true
        };
      if (e.type === "date")
        f = {
          type: "daterange",
          dataType: "datetime",
          title: e?.alias || e.name,
          tooltip: e.name,
          singleDatePicker: e.singleDatePicker ? e.singleDatePicker : false,
          defaultRange: e.defaultRange ? e.defaultRange : "Last 12 months",
          name: this.getFilterName(this.model.name, e.name)
        };

      this.filteringPanel.filters.push(f);
    });
  },
  methods: {
    getFilterName(modelName, entityName) {
      return `${modelName}_${this.$helpers.str2_(entityName)}`;
    },
    onFilteringPanelStateChange() {
      this.$emit("state-change", {
        expressionBuilderVisible: this.$refs.filteringPanel.expressionBuilder
          .visible
      });

      //this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder.visible;
    },
    onFilteringPanelLoad() {
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },

    async loadDictionaries() {
      let self = this;
      let fnArray = [];

      this.filterEntities.forEach(entity => {
        if (entity.type === "dropdown") {
          const fn = () => {
            entity.optionsService().then(response => {
              self.filteringPanel.filters.find(
                f => f.name === this.getFilterName(this.model.name, entity.name)
              ).options = response.map(u => ({
                id: u.id,
                label: u.name
              }));
            });
          };
          fnArray.push(fn());
        }
      });

      await Promise.all(fnArray).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    filterData(e) {

      console.log("FilteringPanelWrapper.filter-data.e:", e);
      this.filteringPanel.selected = e;
    },
    setFilters(e) {
      this.$refs.filteringPanel.selected = e;
    },

    getData() {
      let fp = this.$refs.filteringPanel;
console.log("FilteringPanelWrapper.get-data.fp:", fp);
      if (!fp.isSearchAllowed()) return;

      let f = fp.selected;

      let payload = {};
      this.filteringPanel.filters.forEach(filter => {
        if (filter.type === "select") {
          if (
            Object.prototype.hasOwnProperty.call(filter, "multiple") &&
            filter.multiple == false
          ) {
            payload[filter.name] = fp.selected[filter.name]
              ? fp.selected[filter.name]
              : {};
          } else {
            payload[filter.name] = fp.selected[filter.name]
              ? fp.selected[filter.name].map(i => i.label)
              : [];
          }
        }

        if (
          filter.type === "daterange" && filter.singleDatePicker == false &&
          fp.selected[filter.name] &&
          fp.selected[filter.name].startDate &&
          fp.selected[filter.name].endDate
        ) {
          payload[filter.name] = fp.selected[filter.name];
        }

        if (
          filter.type === "daterange" && filter.singleDatePicker == true &&
          fp.selected[filter.name] &&
          fp.selected[filter.name] 
        ) {
          payload[filter.name] = fp.selected[filter.name];
        }
      });

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }
console.log("FilteringPanelWrapper.get-data.payload:", payload);  
      //this.$refs[this.model.name].getData(payload);
      this.$emit("get-data", payload);
    }
  },
  watch: {}
};
</script>

<style></style>
