var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "delete-button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.handleClick.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "bi bi-x",
    staticStyle: {
      "font-size": "20px",
      "color": "#000"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }