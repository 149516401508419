<template>
  <div>
    <TableWrapper ref="pricelistTemplatesTable" :model="model" :columns="tableColumns" :auto-fetch="true"
      :read-only="false" actions-mode="inline" :filter-by-column="true" @loaded="onTemplatesTableLoaded"
      @updated="onTemplatesTableUpdated" @inserted="onTemplatesTableUpdated" @deleted="onTemplatesTableUpdated">

    
      <div slot="Template URL" slot-scope="props">
        <a :href="props.row['Template URL']" target="_blank" class="template-link">
          {{ props.row['Template URL'] }}
          <font-awesome-icon icon="external-link-alt" />
        </a>
      </div>
    </TableWrapper>

  </div>
</template>

<script>
import model from '@/models/Distributor/pricelist-templates.model';
import TableWrapper from '@/components/DataView/TableWrapper';

export default {
  name: 'PricelistTemplatesTable',
  components: { TableWrapper },

  data() {
    return {
      model: model.pricelistTemplates,
      tableColumns: ['Title', 'Template URL'],
      isEditMode: false,
      currentTemplate: {
        'ID': '',
        'Title': '',
        'Template URL': ''
      }
    };
  },

  methods: {
    onTemplatesTableLoaded() {
      console.log('Templates table loaded');  
      this.$emit('templates-loaded');
    },
    onTemplatesTableUpdated() {
      console.log('Templates table updated');
      this.$emit('templates-updated');
    },

  }
};
</script>

<style scoped>
.template-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.template-link svg {
  margin-left: 5px;
  font-size: 0.8em;
}
</style>
