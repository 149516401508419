var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-row', [_c('b-col', [_c('FilteringPanelWrapper', {
    ref: "filteringPanel",
    attrs: {
      "model": _vm.model,
      "filters": _vm.filters
    },
    on: {
      "get-data": _vm.onFilteringPanelGetData
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('TableWrapper', {
    ref: _vm.model.name,
    attrs: {
      "model": _vm.model,
      "parent-id": _vm.distributor.id,
      "actions-mode": "inline",
      "columns": _vm.tableColumns,
      "read-only": false,
      "filter-by-column": true,
      "slots": _vm.slots,
      "selectable-rows": true
    },
    on: {
      "inserted": _vm.onTableInsert,
      "updated": _vm.onTableChange,
      "row-select": _vm.onRowSelect,
      "loaded": _vm.onTableLoaded,
      "beforeDataDisplay": _vm.onBeforeDataDisplay
    },
    scopedSlots: _vm._u([{
      key: "Price History",
      fn: function fn(props) {
        return _c('div', {
          staticStyle: {
            "display": "flex",
            "justify-content": "center",
            "align-items": "center",
            "width": "100%"
          }
        }, [props.row['Price History'] ? [_c('i', {
          staticClass: "fa fa-search",
          staticStyle: {
            "font-size": "2em",
            "opacity": "0.5",
            "font-weight": "lighter"
          },
          attrs: {
            "id": 'price-history-help-' + props.row.ID
          }
        }), _c('b-popover', {
          attrs: {
            "target": 'price-history-help-' + props.row.ID,
            "triggers": "hover",
            "placement": "right"
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(props.row['Price History'])
          }
        })])] : _vm._e()], 2);
      }
    }])
  }, [_c('div', {
    staticStyle: {
      "margin-top": "-1.5em"
    },
    attrs: {
      "slot": "afterFilterButtons"
    },
    slot: "afterFilterButtons"
  }, [_c('FormSelect', {
    ref: "refTemplateSelect",
    staticClass: "ml-2 d-inline-block",
    staticStyle: {
      "width": "20em"
    },
    attrs: {
      "properties": _vm.templateSelectProperties
    },
    model: {
      value: _vm.selectedTemplate,
      callback: function callback($$v) {
        _vm.selectedTemplate = $$v;
      },
      expression: "selectedTemplate"
    }
  }), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "title": "Manage export templates"
    },
    on: {
      "click": function click($event) {
        return _vm.openTemplateManager();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "pencil-alt"
    }
  })], 1), _c('FormSelect', {
    ref: "currencySelect",
    staticClass: "ml-2 d-inline-block",
    staticStyle: {
      "width": "20em"
    },
    attrs: {
      "properties": _vm.currencySelectProperties
    },
    on: {
      "change": _vm.onCurrencyChange
    },
    model: {
      value: _vm.selectedCurrency,
      callback: function callback($$v) {
        _vm.selectedCurrency = $$v;
      },
      expression: "selectedCurrency"
    }
  }), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "disabled": !_vm.selectedRows.length,
      "title": "Export to Excel"
    },
    on: {
      "click": function click($event) {
        return _vm.export2ExcelHandler2();
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 384 512"
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5L160 336l-60.1-93.5c-5.1-8 .6-18.5 10.1-18.5h34.9c4.4 0 8.5 2.4 10.6 6.3 19.7 36.2 36.6 68.8 36.6 68.8 6.4-14.8 10-20 36.6-68.8 2.1-3.9 6.1-6.3 10.5-6.3H274c9.5-.1 15.2 10.4 10.1 18.5z"
    }
  })]), _vm._v(" Export XLSX ")]), _c('b-button', {
    staticClass: "m-1 width10",
    attrs: {
      "variant": "outline-dark",
      "title": "Send Email"
    },
    on: {
      "click": function click($event) {
        return _vm.showEmailForm();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "paper-plane"
    }
  }), _vm._v(" Email ")], 1), _c('span', [_c('i', {
    staticClass: "fa fa-question-circle",
    staticStyle: {
      "padding-left": "1em",
      "padding-top": "0.3em",
      "font-size": "1.5em"
    },
    attrs: {
      "id": "price-list-help-popover"
    }
  })]), _c('b-popover', {
    attrs: {
      "target": "price-list-help-popover",
      "triggers": "hover",
      "placement": "right"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.priceListHelpTemplate)
    }
  })]), _c('b-alert', {
    staticClass: "d-inline-block ml-2 mb-0 py-1 px-2",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" Sales statistics for the last 12 months in " + _vm._s(_vm.distributor.state.label) + " market ")])], 1)])], 1)], 1), _c('b-row', {
    staticStyle: {
      "margin-bottom": "10em"
    }
  }), _c('b-modal', {
    ref: "template-manager-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": "Export Templates Management",
      "hide-footer": ""
    }
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('PricelistTemplatesTable', {
    ref: "pricelistTemplatesTable",
    on: {
      "templates-updated": _vm.onTemplatesUpdated,
      "templates-loaded": _vm.onTemplatesLoaded
    }
  })], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeTemplateManager();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1), _c('EmailForm', {
    ref: "emailForm",
    attrs: {
      "templates": _vm.emailTemplates,
      "defaultEmail": _vm.emailForm.email,
      "defaultSubject": _vm.emailForm.subject,
      "defaultBody": _vm.emailForm.body,
      "defaultReplyTo": _vm.emailForm.replyTo,
      "defaultFromName": _vm.emailForm.fromName
    },
    on: {
      "template-selected": _vm.loadTemplate,
      "send": _vm.sendEmail
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }