var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('TableWrapper', {
    ref: "pricelistTemplatesTable",
    attrs: {
      "model": _vm.model,
      "columns": _vm.tableColumns,
      "auto-fetch": true,
      "read-only": false,
      "actions-mode": "inline",
      "filter-by-column": true
    },
    on: {
      "loaded": _vm.onTemplatesTableLoaded,
      "updated": _vm.onTemplatesTableUpdated,
      "inserted": _vm.onTemplatesTableUpdated,
      "deleted": _vm.onTemplatesTableUpdated
    },
    scopedSlots: _vm._u([{
      key: "Template URL",
      fn: function fn(props) {
        return _c('div', {}, [_c('a', {
          staticClass: "template-link",
          attrs: {
            "href": props.row['Template URL'],
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(props.row['Template URL']) + " "), _c('font-awesome-icon', {
          attrs: {
            "icon": "external-link-alt"
          }
        })], 1)]);
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }