<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <FilteringPanelWrapper ref="filteringPanel" :model="model" :filters="filters" @get-data="onFilteringPanelGetData" />
      </b-col>
    </b-row>
    <b-row>

      <b-col>
        <TableWrapper :ref="model.name" :model="model" :parent-id="distributor.id" actions-mode="inline"
          :columns="tableColumns" :read-only="false" :filter-by-column="true" :slots="slots" :selectable-rows="true"
          @inserted="onTableInsert" @updated="onTableChange" @row-select="onRowSelect" @loaded="onTableLoaded"
          @beforeDataDisplay="onBeforeDataDisplay">

          <div slot="afterFilterButtons" style="margin-top: -1.5em;">



            <FormSelect ref="refTemplateSelect" class="ml-2 d-inline-block" style="width: 20em;"
              v-model="selectedTemplate" :properties="templateSelectProperties"></FormSelect>


            <b-button variant="outline-dark" class="m-1 " @click="openTemplateManager()"
              title="Manage export templates">
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
            <FormSelect ref="currencySelect" class="ml-2 d-inline-block" style="width: 20em;" v-model="selectedCurrency"
              :properties="currencySelectProperties" @change="onCurrencyChange"></FormSelect>

            <b-button variant="outline-dark" :disabled="!selectedRows.length" @click="export2ExcelHandler2()"
              title="Export to Excel">
              <svg style=" height: 16px; width: 16px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path fill="currentColor"
                  d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5L160 336l-60.1-93.5c-5.1-8 .6-18.5 10.1-18.5h34.9c4.4 0 8.5 2.4 10.6 6.3 19.7 36.2 36.6 68.8 36.6 68.8 6.4-14.8 10-20 36.6-68.8 2.1-3.9 6.1-6.3 10.5-6.3H274c9.5-.1 15.2 10.4 10.1 18.5z" />
              </svg>
              Export XLSX
            </b-button>
            <b-button variant="outline-dark" @click="showEmailForm()" title="Send Email" class="m-1 width10">
              <font-awesome-icon icon="paper-plane" />
              Email
            </b-button>

            <span>
              <i id="price-list-help-popover" class="fa fa-question-circle"
                style="padding-left:1em;padding-top:0.3em;font-size: 1.5em;" />
            </span>
            <b-popover target="price-list-help-popover" triggers="hover" placement="right">
              <span v-html="priceListHelpTemplate" />
            </b-popover>

            <b-alert show variant="info" class="d-inline-block ml-2 mb-0 py-1 px-2">
              Sales statistics for the last 12 months in {{ distributor.state.label }} market
            </b-alert>
          </div>


          <div slot="Price History" slot-scope="props"
            style="display: flex; justify-content: center; align-items: center; width: 100%;">
            <template v-if="props.row['Price History']">
              <i :id="'price-history-help-' + props.row.ID" class="fa fa-search"
                style="font-size: 2em; opacity: 0.5; font-weight: lighter;" />
              <b-popover :target="'price-history-help-' + props.row.ID" triggers="hover" placement="right">
                <span v-html="props.row['Price History']"></span>
              </b-popover>
            </template>
          </div>


        </TableWrapper>
      </b-col>
    </b-row>
    <b-row style="margin-bottom: 10em;"> </b-row>

    <!-- Export templates management modal -->
    <b-modal ref="template-manager-modal" centered scrollable size="lg" title="Export Templates Management" hide-footer>
      <b-container fluid class="ma-0 pa-0">

        <PricelistTemplatesTable ref="pricelistTemplatesTable" @templates-updated="onTemplatesUpdated"
          @templates-loaded="onTemplatesLoaded" />
      </b-container>
      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="closeTemplateManager()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>

    <!-- Email form component -->
    <EmailForm ref="emailForm" :templates="emailTemplates" :defaultEmail="emailForm.email"
      :defaultSubject="emailForm.subject" :defaultBody="emailForm.body" :defaultReplyTo="emailForm.replyTo"
      :defaultFromName="emailForm.fromName" @template-selected="loadTemplate" @send="sendEmail" />
  </div>
</template>

<script>
import model from '@/models/Distributor/distributor-product-prices.model.js'
import FilteringPanelWrapper from "@/components/DataView/FilteringPanelWrapper";
import TableWrapper from "@/components/DataView/TableWrapper";
import FormSelect from "@/components/DataView/FormSelect";
import productServices from '@/services/SalesAdministrative/products.service'
import pricelistServices from '@/services/SalesAdministrative/pricelist.service'
import { ComponentMethods } from './ComponentMethods'
import PricelistTemplatesTable from './PricelistTemplatesTable.vue'
import EmailForm from '@/components/EmailForm/EmailForm.vue'
import { mapState } from 'vuex';
import dictionaryServices from '@/services/dictionary.service';


export default {
  props: {
    distributor: {
      type: Object,

      required: true
    }
  },
  name: 'DistributorProductPrices',
  components: { TableWrapper, FilteringPanelWrapper, FormSelect, PricelistTemplatesTable, EmailForm },
  data: function () {
    return {

      componentMethods: null,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      model: model.distributorProductPrices,
      filters: ["Valid From"],
      tableColumns: ['Product', 'Category', 'Price', 'Price Type', 'Valid From', 'Price History', 'Sales Volume %'],
      slots: ['Price History'],
      data: {
        products: [],
        priceLists: [],
        productStats: [],
        filteredProductStats: []
      },
      selectedRows: [],
      selectedTemplate: null,
      selectedCurrency: { id: 120, label: 'USA, Dollars', rate: 1, symbol: '$', code: 'USD', rate_date: '' }, 
      currencySelectProperties: {
        name: '',
        readonly: false,
        allowEmpty: false,
        multiple: false,
        required: false,
        mode: this.$constants.FORM_MODE.EDIT,
        customOptionsLayout: true,
        optionsService: function () {
          return this.getCurrencies();
        }.bind(this)
      },
      templateSelectProperties: {
        name: '',
        readonly: false,
        allowEmpty: true,
        multiple: false,
        required: false,
        mode: this.$constants.FORM_MODE.EDIT,
        customOptionsLayout: true,
        optionsService: function () {
          return this.getPricelistTemplates();
        }.bind(this)
      },
      emailForm: {
        visible: false,
        email: '',
        subject: '',
        body: '',
        replyTo: '',
        fromName: 'Koval Distillery'
      },
      emailTemplates: [
        { id: 'select', label: 'Select template' }
      ],
      priceListHelpTemplate: `<h6>Price List Help</h6>
  1. To create new custom price for selected product select "Valid from" date that exceeds the pricelist date.<br> 
2. If pricelist date exceeds "Valid from" date, price will be edited without creating new records.<br> 
3. View price history by hovering over the search icon.<br> 
4. Export to Excel by selecting products and clicking "Export XLSX".<br> 
5. Sales statistics based on Warehouse orders for the last 12 months in the distributor's Market and displayed in "Sales Volume %" column.<br>
 6. Template should be in google sheet .xlsx format, and accessible via URL.<br> 
7. Template column headers should be placed in row #2 and start from these names:
  'Product Type',
  'Product Name',
  'Bottle Size',
  'ABV',
  'Bottle Height',
  'Bottle Width',
  'Bottle Weight',
  'Case Size',
  'Cases per Layer',
  'Layers per Pallet',
  'Case Dimensions',
  'Case Height',
  'Case Weight',
  'Case Price', 
  'Bottle Price', 
  'GS1 UPC',
  'SCC Code',
  'NABCA Code',
  'TTB ID',
  'Schedule B',
  'CN Codes EU'<br>
  8. Data will be exported starting from row #3.<br>
  9. Email form will use "Current Contacts" fields at first and field Email in case if no contacts selected.<br> 

`
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  created() {
    this.componentMethods = new ComponentMethods(this);
  },
  async mounted() {
    const distributorEntity = this.model.entities.find(entity => entity.name === 'Distributor ID')

    distributorEntity.defaultValue = this.distributor.id

    this.loadProductStats();

    this.data.products = await productServices.fetchProducts()

  },
  methods: {
    onCurrencyChange(value) {

      //this.selectedCurrency = value;
      console.log('Currency changed to:', value);

      const tableRef = this.$refs[this.model.name];
      if (!tableRef) return;

      this.$nextTick(() => {
      const currentData = tableRef.getDataSet();

      const updatedData = currentData.map(row => {
        const updatedRow = { ...row };

        if (updatedRow['Price'] !== null) {
        if (updatedRow['Original Price USD'] === undefined) {
          updatedRow['Original Price USD'] = updatedRow['Price'];
        }

        updatedRow['Price'] = (parseFloat(updatedRow['Original Price USD']) * this.selectedCurrency.rate).toFixed(2);
        }

        updatedRow['Currency'] = this.selectedCurrency.label;
        updatedRow['Currency ID'] = this.selectedCurrency.id;

        return updatedRow;
      });

      tableRef.updateDataSet(updatedData);
      });
    },
    

    async getCurrencies() {

      let fp = this.$refs.filteringPanel.$refs?.filteringPanel?.selected;
      console.log('selected date', fp);

      const priceDate = fp["distributor_product_prices_valid_from"]
     // console.log('selected date', priceDate);

      const currencies = await dictionaryServices.fetchCurrenciesRates(priceDate);
console.log('getCurrencies', currencies)
      return currencies.map(currency => ({
        id: currency.id,
        label: currency.name + ' [' + parseFloat(currency.rate) + ' - '+currency.date + ']',
        code: currency.code,
        symbol: currency.symbol,
        date: currency.date,
        rate: parseFloat(currency.rate)
      }));
    },
    // Load all product stats, filter stats based on country and state 
    async loadProductStats() {
      this.data.productStats = await productServices.fetchProductStatsWHLTM()

      // Filter productStats based on country and  state / 
      // TODO add Markets as array to distributor object
      this.filteredProductStats = this.data.productStats.filter(stat =>
        stat.Country === this.distributor.country?.label &&
        (this.distributor.state.label === '' || stat.Market === this.distributor.state?.label) && stat["Product_ID"]
      );

      console.log('this.data.productStats', this.data.productStats)
      console.log('this.filteredProductStats', this.filteredProductStats)

    },
    async onFilteringPanelGetData(payload) {

      console.log('onFilteringPanelGetData', payload);  
      await this.$refs[this.model.name].getData(payload);

    },


    selectRowsWithSales(data) {

      const tableRef = this.$refs[this.model.name];
      if (!tableRef || !tableRef.$refs.dataTable) return;

      this.selectedRows = [];

      data.forEach(row => {
        if (parseFloat(row['Sales Volume']) > 0) {
          row['#'] = true;

          this.selectedRows.push(row);
        } else {
          row['#'] = false;
        }
      });

      console.log('Selected rows with sales:', this.selectedRows.length);


    },

    sortByVolumeCategoryProduct(data, updateTable = true) {
      if (!data || !data.length) return data;

      const sortedData = [...data];

      sortedData.sort((a, b) => {
        // Convert Sales Volume to number
        const aVolume = parseFloat(a['Sales Volume']) || 0;
        const bVolume = parseFloat(b['Sales Volume']) || 0;

        const aHasSales = aVolume > 0;
        const bHasSales = bVolume > 0;

        // 1. First, sort by Sales Volume (descending)
        if (aHasSales && !bHasSales) return -1;
        if (!aHasSales && bHasSales) return 1;

        // 2. If both have sales, sort by Sales Volume
        if (aHasSales && bHasSales) {
          const volumeDiff = bVolume - aVolume; //  b - a for descending order
          if (volumeDiff !== 0) return volumeDiff;
        }

        // 3. If Sales Volume is the same, sort by Category
        const categoryCompare = String(a['Category'] || '').localeCompare(String(b['Category'] || ''));
        if (categoryCompare !== 0) return categoryCompare;

        // 4. Sort by Product
        return String(a['Product'] || '').localeCompare(String(b['Product'] || ''));
      });

      // Only update the table if updateTable is true
      if (updateTable) {
        setTimeout(() => {
          this.$refs[this.model.name].updateDataSet(sortedData);
        }, 50);
      }

      return sortedData;
    },
    onBeforeDataDisplay(data, callback) {
      console.log('onBeforeDataDisplay: processing data before display', data.length);

      let totalCases = 0;
      this.filteredProductStats.forEach(item => {
        const cases = parseFloat(item["Cases"]) || 0;
        totalCases += cases;
      });

      let enrichedData = data.map(item => {
        const salesVolume = parseFloat(this.filteredProductStats.find(
          stat => String(stat.Product_ID) === String(item["Product ID"])
        )?.["Cases"]) || 0;

        const salesVolumePercentage = totalCases > 0 && salesVolume > 0 ?
          ((salesVolume / totalCases) * 100).toFixed() + '%' : '';

        if (item["Price"] !== null) {
          item["Original Price USD"] = item["Price"];

          if (this.selectedCurrency && this.selectedCurrency.id !== 120) {
            item["Price"] = (parseFloat(item["Price"]) * this.selectedCurrency.rate).toFixed(2);
          }
        }

        if (this.selectedCurrency) {
          item["Currency"] = this.selectedCurrency.label;
          item["Currency ID"] = this.selectedCurrency.id;
        }

        return {
          ...item,
          "Sales Volume": salesVolume,
          "Sales Volume %": salesVolumePercentage
        };
      });

      const sortedData = this.sortByVolumeCategoryProduct(enrichedData, false);

      this.$nextTick(() => {
        this.$refs[this.model.name].disableSorting();
      });

      console.log('onBeforeDataDisplay: returning processed data', sortedData.length);
      callback(sortedData);

      // Pre-select rows with sales volume > 0
      this.$nextTick(() => {
        this.selectRowsWithSales(sortedData);
      });
    },
    onRowSelect(e) {
      console.log("onRowSelect", e);
      this.selectedRows = e;
    },
    onTableInsert() { },
    onTableChange() { },
    async onTableLoaded() {


    },

    export2ExcelHandler2() {
      this.componentMethods.export2ExcelHandler2()
        .catch(error => console.error('Export error:', error));
    },

    openTemplateManager() {
      this.$refs["template-manager-modal"].show();
    },

    closeTemplateManager() {
      this.$refs["template-manager-modal"].hide();
    },

    async fetchPricelistTemplates() {

      return await pricelistServices.fetchPricelistTemplates();

    },
    async getPricelistTemplates() {

      console.log('getPricelistTemplates');

      const result = await pricelistServices.fetchPricelistTemplates();

      const mappedOptions = result.map(template => ({
        id: template.ID,
        label: template.Title,
        template_url: template['Template URL']
      }));

      if (mappedOptions.length > 0)
        this.selectedTemplate = mappedOptions[0];

      console.log('getPricelistTemplates', mappedOptions);
      return mappedOptions;
    },

    onTemplatesUpdated() {
      console.log('onTemplatesUpdated');

      this.$refs.refTemplateSelect.fetchOptions();

    },

    onTemplatesLoaded(templates) {
      console.log('onTemplatesLoaded', templates);
    },

    async loadEmailTemplates() {
      try {
        const templates = await pricelistServices.fetchEmailTemplates();
        console.log('Email templates loaded:', templates);

        // Keep the default template option
        this.emailTemplates = [
          { id: 'select', label: 'Select template' }
        ];

        // Add templates from API
        if (templates && templates.length > 0) {
          const mappedTemplates = templates.map(template => ({
            id: template.id,
            label: template.value // Use filename as label
          }));

          this.emailTemplates = [...this.emailTemplates, ...mappedTemplates];
        }
      } catch (error) {
        console.error('Error loading email templates:', error);
      }
    },

    async loadTemplate(templateId) {
      console.log('Loading template:', templateId);
      try {
        // Add random parameter to prevent caching
        const response = await this.$api.get(`products/pricelist-email-templates/${templateId}?random=${Math.random()}`, {
          responseType: 'text'
        });

        console.log('Template loaded:', response);

        // Replace template variables
        let processedTemplate = this.replaceTemplateVariables(response);

        // Update email body in the form
        this.emailForm.body = processedTemplate;
        this.$refs.emailForm.formData.body = processedTemplate;
      } catch (error) {
        this.$form.makeToastError('Error loading email template');
        console.error('Error loading email template:', error);
      }
    },

    replaceTemplateVariables(template) {
      // Get first contact name from distributor contacts
      let contactName = '';
      if (this.distributor.current_dist_contacts &&
        this.distributor.current_dist_contacts.length > 0) {
        const firstContact = this.distributor.current_dist_contacts[0];
        contactName = firstContact.name ||
          (firstContact.first_name ? `${firstContact.first_name} ${firstContact.last_name || ''}` : '');
      }

      // If no contact name found, use "Dear valued partners"
      if (!contactName) {
        contactName = 'valued partners';
      }

      // Format today's date
      const today = new Date();
      const formattedDate = today.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });

      // Replace variables in template
      let processedTemplate = template;

      // The template has HTML structure, so we need to preserve it
      // The contact placeholder is inside <b> tags: <b>{{contact}}</b>
      processedTemplate = processedTemplate.replace(/{{contact}}/g, contactName);
      processedTemplate = processedTemplate.replace(/{{effective_date}}/g, formattedDate);

      return processedTemplate;
    },

    async showEmailForm() {
      console.log('showEmailForm');

      // Load email templates
      await this.loadEmailTemplates();

      if (this.$refs.emailForm.showForm && this.$refs.emailForm.isMinimized) {
        this.$refs.emailForm.restore();
        return;
      }

      let recipientEmail = '';

      // Extract emails from distributor contacts
      if (this.distributor.current_dist_contacts &&
        this.distributor.current_dist_contacts.length > 0) {

        const contactEmails = this.distributor.current_dist_contacts
          .filter(contact => contact.email && contact.email.trim())
          .map(contact => contact.email.trim());

        if (contactEmails.length > 0) {
          recipientEmail = contactEmails.join(',');
        }
      }

      // Fallback to distributor email if no contact emails found
      if (!recipientEmail && this.distributor.email) {
        recipientEmail = this.distributor.email;
      }

      this.emailForm.email = recipientEmail;
      this.emailForm.subject = `Price List for ${this.distributor.account_name || 'Distributor'}`;

      // Check if a template is already selected in the form
      const currentTemplate = this.$refs.emailForm?.formData?.template;
      if (currentTemplate && currentTemplate.id !== 'select') {
        // If a template is selected, load it
        await this.loadTemplate(currentTemplate.id);
      } else {
        // Otherwise, clear the body
        this.emailForm.body = '';
      }

      // Use email1 if available, otherwise use email2
      this.emailForm.replyTo = this.profile.data.email1 || this.profile.data.email2 || '';

      this.$refs.emailForm.show({
        email: this.emailForm.email,
        subject: this.emailForm.subject,
        body: this.emailForm.body,
        replyTo: this.emailForm.replyTo,
        fromName: this.emailForm.fromName
      });
    },

    async sendEmail(data) {
      try {
        const formData = new FormData();
        formData.append('from_email', data.replyTo || 'no-reply@koval-distillery.com');
        formData.append('from_name', data.fromName);
        formData.append('email', data.email);
        formData.append('subject', data.subject);
        formData.append('body', data.body);

        if (data.replyTo) {
          formData.append('reply_to', data.replyTo);
        }

        // Add attachments if any
        if (data.attachments && data.attachments.length > 0) {
          data.attachments.forEach((file, index) => {
            formData.append(`attachment_${index}`, file);
          });
        }

        await this.$api.post('/send-email', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        this.$form.makeToastInfo('Email sent successfully');
        this.$refs.emailForm.showForm = false;
      } catch (error) {
        this.$form.makeToastError('Error sending email');
        console.error('Error sending email:', error);
      }
    }
  },

  watch: {}
}
</script>

<style scoped>
.width10 {
  width: 10em;
}

.sales-volume-cell {
  width: 100%;
  height: 30px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.volume-bar {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
}
</style>
