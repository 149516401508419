<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <!--
                    <h5>Activity submit form</h5>
                    <hr />
                    -->
            <b-row>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.user_name.id"
                  :value="data.user_name"
                  :label="controls.user_name.label"
                  :readonly="true"
                  :mode="mode"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-select
                  :ref="controls.category.id"
                  :id="controls.category.id"
                  :value="data.category"
                  :label="controls.category.label"
                  :readonly="controls.category.readonly"
                  :options="controls.category.options"
                  :allow-empty="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="3">
                <div class="d-flex align-items-start">
                  <!--
                                <inline-select style="width: 25em" :id="controls.account.id" :value="data.account" :loading="controls.account.isLoading" :label="controls.account.label" :readonly="controls.account.readonly" :options="controls.account.options" :allowEmpty="false" :mode="mode" @changed="updateSelect" :required="true" />
                                -->

                  <inline-select
                    :id="controls.account.id"
                    :width="25"
                    :link="controls.account.link"
                    :value="data.account"
                    :label="controls.account.label"
                    :allow-empty="false"
                    :readonly="controls.account.readonly"
                    :options="controls.account.options"
                    :mode="mode"
                    :link-mode="true"
                    :loading="controls.account.loading"
                    :async="true"
                    @async-search="onAccountSearch"
                    @link-clicked="onAccountLinkClicked"
                    :required="true"
                    @changed="updateSelect"
                  />

                  <button
                    class="btn btn-secondary btn align-self-center ml-2"
                    type="button"
                    @click="addAccount()"
                  >
                    <i class="fa fa-toolbar fa-plus" style="color: #fff" />
                  </button>
                </div>
              </b-col>
              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.contact.id"
                  :value="data.contact"
                  :loading="controls.contact.isLoading"
                  :label="controls.contact.label"
                  :readonly="controls.contact.readonly"
                  :options="controls.contact.options"
                  :allow-empty="true"
                  :multiple="true"
                  :mode="mode"
                  :link-mode="true"
                  @link-clicked="onContactLinkClicked"
                  @changed="updateSelect"
                  :required="false"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3">
                <inline-date-picker
                  :id="controls.activitydate.id"
                  :value-single="data.activitydate"
                  :label="controls.activitydate.label"
                  :readonly="controls.activitydate.readonly"
                  :mode="mode"
                  @changed="updateDateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-time-picker
                  :id="controls.time_start.id"
                  :value="data.time_start"
                  :label="'From time'"
                  :readonly="controls.time_start.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-time-picker
                  :id="controls.time_end.id"
                  :value="data.time_end"
                  :label="'To time'"
                  :readonly="controls.time_end.readonly"
                  :mode="mode"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="3">
                <div class="d-flex align-items-end">
                      <inline-select
                        :id="controls.project.id"
                        :value="data.project"
                        :label="controls.project.label"
                        :readonly="controls.project.readonly"
                        :options="controls.project.options"
                        :allow-empty="false"
                        :mode="mode"
                        @changed="updateSelect"
                        :required="false"
                        :link-mode="true"
                        @link-clicked="onProjectLinkClicked"
                        style="width:100%"
                      />
                      <delete-button v-if="data.project?.id" @click="unlinkProject(controls.project.id)" 
                          class="ml-2 mb-1"
                      />

                 
                    </div>
                  </b-col>

            </b-row>
            <b-row>
              <b-col lg="12">
                <inline-input
                  :id="controls.subject.id"
                  :value="data.subject"
                  :label="controls.subject.label"
                  :readonly="controls.subject.readonly"
                  :mode="mode"
                  :required="true"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="2">
                <font-awesome-icon icon="chevron-down" />
                <h6
                  v-b-toggle.collapse-1
                  style="display: inline;cursor: pointer"
                >
                  Description
                </h6>
              </b-col>
            </b-row>
            <b-collapse id="collapse-1">
              <b-row>
                <b-col lg="12">
                  <!--
                  <inline-input
                    :id="controls.description.id"
                    :value="data.description"
                    :hide-label="true"
                    :label="controls.description.label"
                    :readonly="controls.description.readonly"
                    :mode="mode"
                    :required="true"
                    :is-text-area="true"
                    @changed="updateField"
                  />
                  -->
                  <ckeditor
                    ref="ckeditor"
                    :id="controls.description.id"
                    :editor="editor"
                    :disabled="controls.editor.readonly"
                    v-model="data.description"
                    :config="controls.editor.config"
                    @blur="onEditorBlur"
                  />
                </b-col>
              </b-row>
            </b-collapse>

            <b-row>
              <b-col cols="2">
                <font-awesome-icon icon="chevron-down" />
                <h6
                  v-b-toggle.collapse-2
                  style="display: inline;cursor: pointer"
                >
                  Tastings
                </h6>
              </b-col>
            </b-row>

            <b-collapse id="collapse-2">
              <hr />
              <b-row>
                <b-col lg="3">
                  <inline-input
                    :id="controls.visitors.id"
                    :value="data.visitors"
                    :label="controls.visitors.label"
                    :readonly="controls.visitors.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3">
                  <inline-input
                    :id="controls.expenses.id"
                    :value="data.expenses"
                    :label="controls.expenses.label"
                    :readonly="controls.expenses.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
              <h5>Products sold</h5>
              <hr />
              <b-row>
                <b-col cols="3">
                  <inline-input
                    :id="controls.WhiteWhisky.id"
                    :value="data.WhiteWhisky"
                    :label="controls.WhiteWhisky.label"
                    :readonly="controls.WhiteWhisky.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col cols="3">
                  <inline-input
                    :id="controls.Whisky.id"
                    :value="data.Whisky"
                    :label="controls.Whisky.label"
                    :readonly="controls.Whisky.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col cols="3">
                  <inline-input
                    :id="controls.Gin.id"
                    :value="data.Gin"
                    :label="controls.Gin.label"
                    :readonly="controls.Gin.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col cols="3">
                  <inline-input
                    :id="controls.Vodka.id"
                    :value="data.Vodka"
                    :label="controls.Vodka.label"
                    :readonly="controls.Vodka.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <inline-input
                    :id="controls.Liqueurs.id"
                    :value="data.Liqueurs"
                    :label="controls.Liqueurs.label"
                    :readonly="controls.Liqueurs.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col cols="3">
                  <inline-input
                    :id="controls.Rum.id"
                    :value="data.Rum"
                    :label="controls.Rum.label"
                    :readonly="controls.Rum.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col cols="3">
                  <inline-input
                    :id="controls.GinGiftPack.id"
                    :value="data.GinGiftPack"
                    :label="controls.GinGiftPack.label"
                    :readonly="controls.GinGiftPack.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col cols="3">
                  <inline-input
                    :id="controls.WhiskeyGiftPack.id"
                    :value="data.WhiskeyGiftPack"
                    :label="controls.WhiskeyGiftPack.label"
                    :readonly="controls.WhiskeyGiftPack.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="3">
                  <inline-input
                    :id="controls.Other.id"
                    :value="data.Other"
                    :label="controls.Other.label"
                    :readonly="controls.Other.readonly"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
            </b-collapse>
          </form>
        </b-tab>

        <b-tab
          :title="controls.tabs.files.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <files-container
            :module-id="$route.meta.module.id"
            :entity-id="id"
            @loaded="onFileContainerLoad"
          />
        </b-tab>
      </b-tabs>
      <hr />
      
      <form-submission-actions
        v-if="action !== 'preview'"
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        :buttons-visibility="{
          previous: $customTable.getPrevKey($route.meta.module, id),
          next: $customTable.getNextKey($route.meta.module, id)
        }"
        @previous-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getPrevKey($route.meta.module, id)
            }
          })
        "
        @next-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getNextKey($route.meta.module, id)
            }
          })
        "
        @save="
          save('tabular').then(response =>
            response
              ? $router.push($store.getters['router/previousRoute'])
              : false
          )
        "
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'Activity submission',
                  params: { action: 'view', id: response }
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'Activity submission',
            params: { action: 'edit', id: id }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
        @custom-click="
          name => {
            this[name]();
          }
        "
        :custom-buttons="[
          {
            text: 'Mark as Reviewed',
            icon: 'signature',
            loading: false,
            visible:
              isReviewer &&
              !(mode === $constants.FORM_MODE.CREATE) &&
              !data.reviewed &&
              $permitted(controls.buttons.set_reviewed.id).visible,
            method: 'onReviewedClick',
            variant: 'warning'
          },
          {
            text: 'Schedule task',
            icon: 'clock',
            loading: false,
            visible: !(mode === $constants.FORM_MODE.CREATE),
            method: 'onCreateTaskClick',
            variant: 'primary',
            menu: [
              {
                label: '1 day later',
                method: 'onCreateTaskClickDay'
              },
              {
                label: '1 week later',
                method: 'onCreateTaskClickWeek'
              },
              {
                label: '2 weeks later',
                method: 'onCreateTaskClickTwoWeeks'
              },
              {
                label: '1 month later',
                method: 'onCreateTaskClickMonth'
              }
            ]
          }
        ]"
      />
    </b-card>
    <project-preview-modal ref="project-preview-modal"></project-preview-modal>
    <b-modal
      ref="contact-preview-modal"
      centered
      scrollable
      size="lg"
      :title="contactPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh;">
        <ContactSubmissionForm :id="contactPreviewModal.id" action="preview" />
      </b-container>
      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openContactInNewWindow(contactPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>

          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeContactPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="account-preview-modal"
      centered
      scrollable
      size="lg"
      :title="accountPreviewModal.title"
    >
      <div class="ma-0 pa-0" style="height: 80vh;border:none !important;">
        <AccountSubmissionForm :id="accountPreviewModal.id" action="preview" />
      </div>

      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openAccountInNewWindow(accountPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>

          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeAccountPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      ref="task-submission-modal"
      centered
      scrollable
      size="lg"
      hide-footer
      :title="taskSubmissionModal.title"
    >
      <TaskSubmissionForm
        action="create"
        :properties="taskSubmissionModal.data"
        :create-task-action="true"
        @save="onTaskModalSave"
        @close="onTaskModalClose"
      />
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";

import sharedEvents from "@/shared/sharedEvents.js";

import ProjectPreviewModal from "@/views/ProjectManagement/ProjectPreviewModal";
import { FORM_MODE, MODULES } from '@/shared/constants'

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";
import InlineTimePicker from "@/components/InlineTimePicker";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import AccountSubmissionForm from "@/views/RelationshipManagement/AccountSubmissionForm/index.js";
import ContactSubmissionForm from "@/views/RelationshipManagement/ContactSubmissionForm/index.js";

import TaskSubmissionForm from "@/views/ProjectManagement/TaskSubmissionForm";

import { mapState, mapActions } from "vuex";

import FilesContainer from "@/components/FilesContainer";

import { completeActivityTask } from "@/views/ProjectManagement/helpers.js";

import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import DeleteButton from "../../components/DeleteButton.vue";

Vue.use(CKEditor);

export default {
  name: "ActivitySubmissionForm",
  props: {
    id: {
      type: [Number, String],
      required: false,
      default: ""
    },
    action: {
      type: String,
      default: ""
    },
    projectId: {
      type: Number,
      required: false,
      default: () => {}
    },
    params: {
      type: Object,
      required: false,
      default: () => {
        return {
          task_id: "",
          account_id: "",
          category_id: "",
          subject: "",
          description: ""
        };
      }
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,
    InlineTimePicker,
    FilesContainer,
    ContactSubmissionForm,
    FormSubmissionActions,
    AccountSubmissionForm,
    TaskSubmissionForm,
    ckeditor: CKEditor.component,
    ProjectPreviewModal,
    DeleteButton
  },
  data: function() {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      isReviewer: false,
      editor: InlineEditor,
      MODULES: MODULES,
      FORM_MODE: FORM_MODE,
      contactPreviewModal: {
        id: "",
        title: ""
      },
      mode: undefined,
      accountPreviewModal: {
        id: "",
        title: ""
      },
      taskSubmissionModal: {
        id: "",
        title: "",
        data: {
          task_name: "",
          description: "",
          start_date: "",
          end_date: "",
          status: {},
          account: {}
        }
      },
      

      controls: {
        tabs: {
          files: {
            title: "Files"
          }
        },
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          },
          set_reviewed: {
            id: "button:set_reviewed"
          }
        },
        editor: {
          data: "",
          config: {
            startupFocus: true,
            startupShowBorders: true
            // readOnly: true,
          },
          readonly: false
        },
        user_name: {
          id: "input:user_name",
          label: "User Name",
          changed: false,
          readonly: false
        },
        category: {
          id: "select:category",
          label: "Category",
          options: [],
          changed: false,
          readonly: false
        },
        account: {
          id: "select:account",
          label: "Account",
          options: [],
          changed: false,
          readonly: false,
          isLoading: false,
          loading: false
        },
        project: {
          id: 'select:project',
          label: 'Project',
          options: [],
          changed: false,
          readonly: false
        },
        contact: {
          id: "select:contact",
          label: "Contact",
          options: [],
          changed: false,
          readonly: false,
          isLoading: false
        },
        activitydate: {
          id: "datepicker:activitydate",
          label: "Activity Date",
          changed: false,
          readonly: false
        },
        time_start: {
          id: "timepicker:time_start",
          label: "From",
          changed: false,
          readonly: false
        },
        time_end: {
          id: "timepicker:time_end",
          label: "To",
          changed: false,
          readonly: false
        },
        subject: {
          id: "input:subject",
          label: "Subject",
          changed: false,
          readonly: false
        },
        description: {
          id: "input:description",
          label: "Description",
          changed: false,
          readonly: false
        },
        visitors: {
          id: "input:visitors",
          label: "Visitors",
          changed: false,
          readonly: false
        },
        expenses: {
          id: "input:expenses",
          label: "Expenses",
          changed: false,
          readonly: false
        },
        WhiteWhisky: {
          id: "input:WhiteWhisky",
          label: "White Whisky",
          changed: false,
          readonly: false
        },
        Whisky: {
          id: "input:Whisky",
          label: "Whisky",
          changed: false,
          readonly: false
        },
        Gin: {
          id: "input:Gin",
          label: "Gin",
          changed: false,
          readonly: false
        },
        Vodka: {
          id: "input:Vodka",
          label: "Vodka",
          changed: false,
          readonly: false
        },
        Liqueurs: {
          id: "input:Liqueurs",
          label: "Liqueurs",
          changed: false,
          readonly: false
        },
        Rum: {
          id: "input:Rum",
          label: "Rum",
          changed: false,
          readonly: false
        },
        GinGiftPack: {
          id: "input:GinGiftPack",
          label: "Gin Gift Pack",
          changed: false,
          readonly: false
        },
        WhiskeyGiftPack: {
          id: "input:WhiskeyGiftPack",
          label: "Whiskey Gift Pack",
          changed: false,
          readonly: false
        },
        Other: {
          id: "input:Other",
          label: "Other",
          changed: false,
          readonly: false
        }
      },
      data: {
        id: "",
        user_id: "",
        user_name: "",
        user_role: "",
        category: {
          id: "",
          label: ""
        },
        account: {
          id: "",
          label: ""
        },
        project: {
          id: "",
          label: ""
        },
        contact: [],
        activitydate: "",
        time_start: "",
        time_end: "",
        subject: "",
        description: "",
        visitors: "",
        expenses: "",
        WhiteWhisky: "",
        Whisky: "",
        Gin: "",
        Vodka: "",
        Liqueurs: "",
        Rum: "",
        GinGiftPack: "",
        WhiskeyGiftPack: "",
        Other: "",
        reviewed: true
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  created() {
    this.initialize();
  },
  mounted() {
    //window.addEventListener("storage", this.onStorageUpdate);
    sharedEvents.subscribe(this.onSharedEvent, "newAccountCreated");
  },
  beforeDestroy() {
    sharedEvents.destroy(this.onSharedEvent);
  },
  methods: {
    test() {
      //this.$router.push( this.$store.getters['router/previousRoute'] )
      //console.log(this.$store.getters['router/previousRoute'].name)
    },
    ...mapActions("profile", ["fetchProfile"]),
    onAccountSearch(payload) {
      let self = this;

      self.controls.account.loading = true;

      this.$api
        .post("dictionaries/accounts", {
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.controls.account.loading = false;

          self.controls.account.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
    },
    onAccountLinkClicked(e) {
      this.accountPreviewModal.id = e.id;
      this.accountPreviewModal.title = `Account ${e.label}`;

      this.$refs["account-preview-modal"].show();
    },
    closeAccountPreview: function() {
      this.$refs["account-preview-modal"].hide();
    },
    openAccountInNewWindow(account_id) {
      let routeData = this.$router.resolve({
        name: "Account submission",
        params: {
          id: account_id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    },
    onContactLinkClicked(e) {
      this.contactPreviewModal.id = e.id;
      this.contactPreviewModal.title = `Contact ${e.label}`;

      this.$refs["contact-preview-modal"].show();
    },
    openContactPreview() {
      this.$refs["contact-preview-modal"].show();
    },
    closeContactPreview: function() {
      this.$refs["contact-preview-modal"].hide();
    },
    openContactInNewWindow(contact_id) {
      let routeData = this.$router.resolve({
        name: "Contact submission",
        params: {
          id: contact_id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    },
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    },

    onSharedEvent(e) {
      if (e === "newAccountCreated") {
        this.$api.get("accounts").then(response => {
          this.controls.account.options = this.$helpers.getDistinctArray(
            response,
            "ID",
            "Account Name",
            "id",
            "label"
          );
        });
      }
    },
    onStorageUpdate() {},
    initialize() {
      console.log("init:", JSON.stringify(this.params));
      let self = this;

      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      //this.updateControlsState();

      this.data.activityId = this.id;

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.user_name = `${this.profile.data.first_name} ${this.profile.data.last_name}`;
        this.data.user_id = this.profile.data.id;
        this.data.user_role = this.profile.data.role;
      }

      this.$api.get("activities/types").then(response => {
        this.controls.category.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });

      this.controls.contact.isLoading = true;

      this.$api.get("dictionaries/contacts").then(response => {
        this.controls.contact.isLoading = false;

        this.controls.contact.options = response.map(item => {
          return {
            id: item["ID"],
            label: `${item["Contact Name"]} [${item["State"]} ${item["Email"]}]`
          };
        });

        //20201203
        //this.bindContactsMultiselect()
        //self.flags.contactsLoaded = true
      });


      this.$api.post('dictionaries/projects', { active: 1 }).then(response => {
        this.controls.project.options = this.$helpers.getDistinctArray(
          response,
          'id',
          'name',
          'id',
          'label'
        )

        if (this.mode === FORM_MODE.CREATE && this.projectId > 0) {
          this.data.project = this.controls.project.options.find(
            p => p.id == this.projectId
          )
        }
      })

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.fetchActivity(this.id);
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.activitydate = this.$nowClient.format("YYYY-MM-DD");
        this.data.time_start = this.$nowClient.format("HH:mm");
        this.data.time_end = this.$nowClient.add(30, "minutes").format("HH:mm");

        if (this.params.account_id) {
          this.$api.get(`accounts/${this.params.account_id}`).then(res => {
            self.data.account = {
              id: res["ID"],
              label: res["Account Name"]
            };
          });
        }
        if (this.params.user_id) {
          this.$api.get(`user/${this.params.user_id}`).then(res => {
            self.data.user_id = res.id;
            self.data.user_name = `${res.first_name} ${res.last_name}`;
            self.data.user_role = res.roleid;

            self.data.activitydate = this.params.activity_date;
          });
        }
        //if properties passed from task
        if (this.params.task_id) {
          self.data.category = {
            id: this.params.category_id,
            label: this.params.category_id
          };
          self.data.subject = this.params.subject;
          self.data.description = this.params.description;
        }
      }
    },
    onCreateTaskClickDay() {
      const date = moment(this.data.activitydate)
        .add(1, "day")
        .format("YYYY-MM-DD");
      //const date = moment().add(1,'day').format('YYYY-MM-DD')

      this.onCreateTaskClick(date);
    },
    onCreateTaskClickWeek() {
      const date = moment(this.data.activitydate)
        .add(1, "week")
        .format("YYYY-MM-DD");
      //const date = moment().add(1,'week').format('YYYY-MM-DD')

      this.onCreateTaskClick(date);
    },
    onCreateTaskClickTwoWeeks() {
      const date = moment(this.data.activitydate)
        .add(2, "week")
        .format("YYYY-MM-DD");
      //const date = moment().add(1,'week').format('YYYY-MM-DD')

      this.onCreateTaskClick(date);
    },

    onCreateTaskClickMonth() {
      const date = moment(this.data.activitydate)
        .add(1, "month")
        .format("YYYY-MM-DD");
      //const date = moment().add(1,'month').format('YYYY-MM-DD')

      this.onCreateTaskClick(date);
    },
    onCreateTaskClick(_date) {
      let next_date = _date ? _date : this.data.activitydate;

      this.taskSubmissionModal.title = "Create task";

      this.taskSubmissionModal.data = {
        task_name: "Follow Up " + this.data.subject,
        description: this.data.description,
        status: { id: "Planned", label: "Planned" },
        priority: { id: "Medium", label: "Medium" },
        date_start: next_date,
        date_end: next_date,
        account: this.data.account,
        //action will be displayed at the bottom of the task form as a button
        //it will be stored to the database in JSON format
        action: {
          title: "Submit activity",
          //method declared in the TaskSubmissionForm.vue
          method: "onActionClick",
          params: {
            category_id: this.data.category.id ? this.data.category.label : "",
            account_id: this.data.account.id ? this.data.account.id : "",
            subject: this.data.subject,
            description: this.data.description
          }
        }
      };

      this.$refs["task-submission-modal"].show();
    },
    onTaskModalSave() {
      this.$refs["task-submission-modal"].hide();
    },
    onTaskModalClose() {
      this.$refs["task-submission-modal"].hide();
    },
    onReviewedClick() {
      this.$api
        .put(`activities/${this.data.id}/reviewed`)
        .then(response => {
          this.$form.makeToastInfo(response.message);

          this.initialize();
        })
        .catch(error => {
          console.log(error);

          this.$form.makeToastError(error.message);
        });
    },

    save(_mode) {
      let self = this;

      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      //let self = this;

      let url =
        this.mode === this.$constants.FORM_MODE.CREATE
          ? "activities/create"
          : "activities/update";

      return this.$api
        .put(url, this.data)
        .then(async response => {
          self.saveInProgress = false;
          self.saveAndViewInProgress = false;

          //if created from task then complete parent task
          if (self.params.task_id) {
            await completeActivityTask(self.params.task_id, self);
          }

          self.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.$form.makeToastError(error.message);
          return Promise.resolve(false);
        });
    },
    updateField(field, value) {


      console.log('update field',field,value) 

      let self = this;
      let params = {};
      params["id"] = this.id;
      params[field] = value;

      //update local data values - it can be used in Edit action
      self.data[field] = value;

      //if control in View mode then update database
      if (this.mode === this.$constants.FORMCONTROLMODE.VIEW)
        this.$api
          .put("activities/update", params)
          .then(response => {
            this.$form.makeToastInfo(`${field}: ${response.message}`);

            self.controls[field].changed = false;
          })
          .catch(response => {
            console.log(response);

            this.$form.makeToastError(`${field}: ${response.message}`);
          });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateSelect(id, value) {

      

      this.updateField(id, value);
    },

    addAccount: function() {
      this.$router.push({
        name: "Account submission",
        params: {
          action: "create"
        }
      });
    },
    fetchActivity: async function(id) {
      let self = this;

      return this.$api
        .get(`activities/${id}`)
        .then(response => {
          if (self.$_.isEmpty(response)) return;

          self.data.account = {
            id: response["Account ID"],
            label: response["Account Name"]
          };

          self.data.category = {
            id: response["Category"],
            label: response["Category"]
          };

          self.data.id = self.id;
          self.data.user_name = response["Submitter"];
          self.data.subject = response["Subject"];
          self.data.description = response["Content"];
          self.data.time_start = response["Start Time"];
          self.data.time_end = response["End Time"];
          self.data.activitydate = response["Activity Date"];

          self.data.visitors = response.Visitors;
          self.data.expenses = response.Expenses;
          self.data.WhiteWhisky = response.WhiteWhisky;
          self.data.Whisky = response.Whisky;
          self.data.Vodka = response.Vodka;
          self.data.Gin = response.Gin;
          self.data.Rum = response.Rum;
          self.data.Liqueurs = response.Liqueurs;
          self.data.Other = response.Other;
          self.data.GinGiftPack = response.GinGiftPack;
          self.data.WhiskeyGiftPack = response.WhiskeyGiftPack;

          self.data.reviewed = response.Reviewed === "Yes";

          if (response["contact_ids"]) {
            self.data.contact_ids = response["contact_ids"];

            self.data.contact_labels = response["contact_labels"];

            self.bindContactsMultiselect();
          }

          self.data.project = {
            id: response['Project ID'],
            label: response['Project Name']
          }
        })
        .catch(error => {
          console.log(error);

          this.$emit("message", {
            title: "Error",
            text: error.message
          });
        });
    },
    async  unlinkProject(payload) {

      console.log('unlink project',payload)

      const confirm = await this.$form.showConfirmation('Are you sure you want to unlink this activity from project?')
      
      if (!confirm) return
      
      this.data.project = null

      this.updateField('project', null);

     
    },
    onProjectLinkClicked (e) {
      this.$refs['project-preview-modal'].show(e)
    },
    bindContactsMultiselect() {
      let self = this;

      if (!self.data.contact_ids) return;

      self.data.contact = [];

      let contact_ids = self.data.contact_ids.split(",");
      let contact_labels = self.data.contact_labels.split(",");
      //let contact_names = response['Account Name'].split(',')

      for (let i = 0; i < contact_ids.length; i++) {
        self.data.contact.push({
          id: contact_ids[i].trim(),
          label: contact_labels[i].trim()
        });
      }
    },
    /*

        bindContactsMultiselect() {

            let self = this

            if (!self.data.contact_ids) return

            self.data.contact = []

            let contact_ids = self.data.contact_ids.split(',')
            //let contact_names = response['Account Name'].split(',')

            for (let i = 0; i < contact_ids.length; i++) {
                self.data.contact.push({
                    id: contact_ids[i].trim(),
                    label: self.controls.contact.options.find(c => c.id === contact_ids[i]).label
                });
            }

        },
        */
    updateControlsState: function() {
      let _readonly = true;

      if (
        this.mode === this.$constants.FORM_MODE.CREATE ||
        this.mode === this.$constants.FORM_MODE.EDIT
      ) {
        _readonly = false;
      }

      for (var prop in this.controls) {
        if (Object.prototype.hasOwnProperty.call(this.controls, prop)) {
          this.controls[prop].readonly = _readonly;
        }
      }
    },

    onEditorBlur() {
      if (this.mode !== this.$constants.FORM_MODE.VIEW) return;

      this.updateField("description", this.data.description);
    }
  },
  watch: {}
};
</script>

<style scoped>
:v-deep .vue-daterange-picker {
  display: block !important;
}

:v-deep .reportrange-text {
  border: 1px solid #e4e7ea !important;
}

:v-deep .vue__time-picker input.display-time {
  border: 0px solid #d2d2d2;
  width: 7em;
  height: 2.2em;
  margin: -0.3em -0.5em;
}

</style>
