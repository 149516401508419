<template>
    <button class="delete-button" @click.stop="handleClick">
      <i class="bi bi-x" style="font-size: 20px; color: #000;"></i>
    </button>
  </template>
  
  <script>
  export default {
    name: 'DeleteButton',
    props: {},
    methods: {
      handleClick() {
        this.$emit('click');
      }
    }
  }
  </script>
  
  <style scoped>
  .delete-button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.7);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    margin-top: 6px;
    transform: translateY(2px);
  }
  
  .delete-button:hover {
    background: #f8f9fa;
  }
  </style>