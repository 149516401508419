import formHelpers from '@/shared/form-helpers'

export class ComponentMethods {
  constructor(component) {
    this.component = component;
  }
  convertGoogleSheetsUrl(url) {
    // Check if URL is valid
    if (!url || typeof url !== 'string' || !url.includes('docs.google.com/spreadsheets')) {
      throw new Error('Invalid Google Sheets URL');
    }
    
    // Extract spreadsheet ID from URL
    const matches = url.match(/\/d\/([a-zA-Z0-9-_]+)/);
    if (!matches || !matches[1]) {
      throw new Error('Failed to extract spreadsheet ID from URL');
    }
    
    const spreadsheetId = matches[1];
    return `https://docs.google.com/spreadsheets/d/${spreadsheetId}/export?format=xlsx`;
  }
  async export2ExcelHandler2() {
    const selectedRows = this.component.selectedRows;
    const distributor = this.component.distributor;
    const products = this.component.data.products;
    const selectedCurrency = this.component.selectedCurrency;

    const showToast = (message, title, variant) => {
      this.component.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    };

    try {
      console.log('Starting Excel export');
      const FileSaver = require('file-saver');
      const ExcelJS = require('exceljs');

      const loadGoogleSheetTemplate = async () => {
        try {
          console.log('Loading template');

          // Get the selected template or use default
          const selectedTemplate = this.component.selectedTemplate;

          console.log('loadGoogleSheetTemplate.selectedTemplate:',selectedTemplate);

          let exportUrl;

          try{
            exportUrl = this.convertGoogleSheetsUrl(selectedTemplate.template_url);
          }
          catch(e){
            console.error('Error:',e);
           formHelpers.showError('Invalid Google Sheets URL');  
          }

          const response = await fetch(exportUrl);
          if (!response.ok) {
            throw new Error(`Template not found: HTTP ${response.status}`);
          }

          // Use the original workbook instead of creating a new one
          // This preserves all formatting, images, and merged cells
          const workbook = new ExcelJS.Workbook();
          const arrayBuffer = await response.arrayBuffer();
          await workbook.xlsx.load(arrayBuffer);

          // Get the worksheet with the logo
          const worksheet = workbook.getWorksheet(1);

          // Read headers from the second row of the template
          const headers = worksheet.getRow(2).values || [];

          // Skip to row 3 (after headers) and start adding data
          const startRow = 3;
          console.log(`Starting data fill from row ${startRow}, selected rows: ${selectedRows.length}`);

          // Define the fields we want to export based on the original function
          const fieldsToExport = [
            'Product Type',
            'Product Name',
            'Bottle Size',
            'ABV',
            'Bottle Height',
            'Bottle Width',
            'Bottle Weight',
            'Case Size',
            'Cases per Layer',
            'Layers per Pallet',
            'Case Dimensions',
            'Case Height',
            'Case Weight',
            'Case Price', 
            'Bottle Price', 
            'GS1 UPC',
            'SCC Code',
            'NABCA Code',
            'TTB ID',
            'Schedule B',
            'CN Codes EU'
          ];

          // For each selected product
          selectedRows.forEach((row, index) => {
            // Find matching product from products list using Product ID
            const productId = row["Product ID"];

            console.log('data.products', products);
            const product = products.find(p => p.ID === productId);

            if (product) {
              // Calculate case price and bottle price
              const originalPrice = row["Original Price USD"] || row["Price"] || 0;
              const bottlePrice = (parseFloat(originalPrice) * selectedCurrency.rate).toFixed(2);
              const caseSize = parseFloat(product['Case Size']) || 0;
              const casePrice = bottlePrice * caseSize;

              // Create a mapping between Excel columns and our fields
              const columnMapping = {};

              // For each header in the Excel template
              headers.forEach((header, colIndex) => {
                if (!header) return;

                const headerLower = String(header).toLowerCase();

                // Check if this header matches any of our fields
                for (const field of fieldsToExport) {
                  const fieldLower = field.toLowerCase();
                  if (headerLower.startsWith(fieldLower)) {
                    columnMapping[colIndex] = field;
                    break;
                  }
                }
              });

              // Log the mapping to console
              console.log('Mapping for product:', product['Product Name']);
              const mappingLog = Object.entries(columnMapping)
                .map(([colIndex, fieldName]) => `'${fieldName}' = ${colIndex}`)
                .join(', ');
              console.log('mapping: ' + mappingLog);

              // Get the row to modify
              const currentRow = worksheet.getRow(startRow + index);

              // Fill the row using the mapping
              for (const [colIndex, fieldName] of Object.entries(columnMapping)) {
                if (fieldName === 'Case Price') {
                  currentRow.getCell(Number(colIndex)).value = casePrice;
                } else if (fieldName === 'Bottle Price') {
                  currentRow.getCell(Number(colIndex)).value = bottlePrice;
                } else {
                  currentRow.getCell(Number(colIndex)).value = product[fieldName] || '';
                }
              }

              // Commit row changes
              currentRow.commit();

              console.log(`Exported product ${productId} (${product['Product Name']})`);
            } else {
              console.warn(`Product with ID ${productId} not found in products data`);
            }
          });

          // Generate filename
          const date = new Date();
          const fileName = `${distributor.account_name}_pricelist_${selectedCurrency.code}_${date.toISOString().split('T')[0]}.xlsx`;
         

          // Save Excel file preserving all formatting and images
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });

          FileSaver.saveAs(blob, fileName);
          console.log('Export completed successfully');

          if (showToast) {
            showToast('Data exported successfully', 'Success', 'success');
          }

        } catch (error) {
          console.error('Template loading error:', error);
          if (showToast) {
            showToast(`Export error: ${error.message}`, 'Error', 'danger');
          }
          throw error;
        }
      };

      // Execute template loading and data export
      await loadGoogleSheetTemplate();

    } catch (error) {
      console.error('General export error:', error);
      if (showToast) {
        showToast(`Export error: ${error.message}`, 'Error', 'danger');
      }
      throw error;
    }
  }

  async export2ExcelHandler2_0() {
    const selectedRows = this.component.selectedRows;
    const distributor = this.component.distributor;
    const products = this.component.data.products;

    const showToast = (message, title, variant) => {
      this.component.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    };

    try {
      console.log('Starting Excel export');
      const FileSaver = require('file-saver');
      const ExcelJS = require('exceljs');

      const loadGoogleSheetTemplate = async () => {
        try {
          console.log('Loading Google Sheets template');

          // Download original template with logo
          const spreadsheetId = '1A8A4Eps_6AobXOu7eQxOCXhoZDthuFvUZXDkKueIjhY';
          const exportUrl = `https://docs.google.com/spreadsheets/d/${spreadsheetId}/export?format=xlsx`;

          const response = await fetch(exportUrl);
          if (!response.ok) {
            throw new Error(`Template not found: HTTP ${response.status}`);
          }

          // Use the original workbook instead of creating a new one
          // This preserves all formatting, images, and merged cells
          const workbook = new ExcelJS.Workbook();
          const arrayBuffer = await response.arrayBuffer();
          await workbook.xlsx.load(arrayBuffer);

          // Get the worksheet with the logo
          const worksheet = workbook.getWorksheet(1);

          // Skip to row 3 (after headers) and start adding data
          const startRow = 3;
          console.log(`Starting data fill from row ${startRow}, selected rows: ${selectedRows.length}`);

          // For each selected product
          selectedRows.forEach((row, index) => {
            // Find matching product from products list using Product ID
            const productId = row["Product ID"];

            console.log('data.products', products);
            const product = products.find(p => p.ID === productId);

            if (product) {
              // Calculate case price and bottle price
              const bottlePrice = row["Price"] || 0;
              const caseSize = parseFloat(product['Case Size']) || 0;
              const casePrice = bottlePrice * caseSize;

              // Get the row to modify
              const currentRow = worksheet.getRow(startRow + index);

              // Map product data to columns
              currentRow.getCell(1).value = product['Product Type'] || '';
              currentRow.getCell(2).value = product['Product Name'] || '';
              currentRow.getCell(3).value = product['Bottle Size'] || '';
              currentRow.getCell(4).value = product['ABV'] || '';
              currentRow.getCell(5).value = product['Bottle Height'] || '';
              currentRow.getCell(6).value = product['Bottle Width'] || '';
              currentRow.getCell(7).value = product['Bottle Weight'] || '';
              currentRow.getCell(8).value = product['Case Size'] || '';
              currentRow.getCell(9).value = product['Cases per Layer'] || '';
              currentRow.getCell(10).value = product['Layers per Pallet'] || '';
              currentRow.getCell(11).value = product['Case Dimensions'] || '';
              currentRow.getCell(12).value = product['Case Height'] || '';
              currentRow.getCell(13).value = product['Case Weight'] || '';
              currentRow.getCell(14).value = casePrice || '';
              currentRow.getCell(15).value = bottlePrice || '';
              currentRow.getCell(16).value = product['GS1 UPC'] || '';
              currentRow.getCell(17).value = product['SCC Code'] || '';
              currentRow.getCell(18).value = product['NABCA Code'] || '';
              currentRow.getCell(19).value = product['TTB ID'] || '';
              currentRow.getCell(20).value = product['Schedule B'] || '';
              currentRow.getCell(21).value = product['CN Codes EU'] || '';

              // Commit row changes
              currentRow.commit();

              console.log(`Exported product ${productId} (${product['Product Name']})`);
            } else {
              console.warn(`Product with ID ${productId} not found in products data`);
            }
          });

          // Generate filename
          const date = new Date();
          const fileName = `${distributor.account_name}_pricelist_${date.toISOString().split('T')[0]}.xlsx`;

          // Save Excel file preserving all formatting and images
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });

          FileSaver.saveAs(blob, fileName);
          console.log('Export completed successfully');

          if (showToast) {
            showToast('Data exported successfully', 'Success', 'success');
          }

        } catch (error) {
          console.error('Template loading error:', error);
          if (showToast) {
            showToast(`Export error: ${error.message}`, 'Error', 'danger');
          }
          throw error;
        }
      };

      await loadGoogleSheetTemplate();

    } catch (error) {
      console.error('General export error:', error);
      if (showToast) {
        showToast(`Export error: ${error.message}`, 'Error', 'danger');
      }
      throw error;
    }
  }

}
